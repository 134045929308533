.Horizon__blog {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;    
   
}

.Horizon__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.Horizon__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.Horizon__blog-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 2rem;
}

.Horizon__blog-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
    padding-bottom: 2rem;
}

.Horizon__blog-container_groupB {
    
    flex: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}
.Horizon__blog-container_groupB p {
    color: azure;
}
/* .Horizon__blog-container_groupB .Horizon__blog-container_article {
    border-radius: 12px;
    
}
.Horizon__blog-container_groupB .Horizon__blog-container_article:hover {
    cursor:pointer;
    border-radius: 12px;
    z-index: 1;
    box-shadow: 0 0 25px rgba(233, 68, 68, 0.1);

} */
.Horizon__blog-container_groupB .Horizon__blog-container_article {
    
    border-radius: 12px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.Horizon__blog-container_groupB .Horizon__blog-container_article:hover {
    cursor:pointer;
    border-radius: 12px;
    z-index: 1;
    transform: scale(1.05); /* Zoom in effect */
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), 0 0 50px rgba(0, 0, 0, 0.2), 0 0 50px cyan, 0 0 110px hotpink;
}

.Horizon__blog-container_groupB .Horizon__blog-container_article-image img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

}
@media screen and (max-width: 990px) {
    .Horizon__blog-container {
        flex-direction: column-reverse;
    }


    .Horizon__blog-container_groupA {
        margin: 2rem 0;
    }

    .Horizon__blog-container_groupB .Horizon__blog-container_article  {
       width: 98%;
       padding-left: 0.2cm;
       padding-right: 0.2cm;

    }
    .Horizon__blog-container_groupB {
        grid-template-columns: repeat(2, 1fr);
    }
    .Horizon__blog-container_groupB .Horizon__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .Horizon__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .Horizon__blog-container_groupA .Horizon__blog-container_article  {
       width: 100%;
    }

    .Horizon__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {

    .Horizon__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
    
    .Horizon__blog {
        padding-left: 2rem;
        padding-right: 2rem;
    }

}



/* loading css */
