
  ::-webkit-scrollbar {
    width: 0px;
  }
  .Horizon__brand {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .Horizon__brand div {
    flex: 1;
    min-width: 120px;
    width: 300px;
    margin: 0.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-position: 50%;
  } 
  .Horizon__container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: inline-block;
    width: 100%;
    height: 250px;
  }
  .slider-card {
    flex-shrink: 0;
    flex-flow: row;
    width: 10px;
    height: 120px;
    border-radius: 12px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    position: relative;
    padding :1rem;
    margin: 1rem;
  }
    
  
  