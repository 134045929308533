*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
   
        /* outline: 1px solid lime; */
      
}

body{
    background:  var(--color-bg);
}

a{
    color: unset;
    text-decoration: none;

}

.gradient__bg
{

    

        /* ff 3.6+ */
        background:-moz-linear-gradient(54deg, rgba(219, 142, 255, 1) 0%, rgba(219, 142, 255, 1) 0%, rgba(50, 151, 254, 1) 50%, rgba(10, 239, 233, 1) 100%); 
    
        /* safari 5.1+,chrome 10+ */
        background:-webkit-linear-gradient(54deg, rgba(219, 142, 255, 1) 0%, rgba(219, 142, 255, 1) 0%, rgba(50, 151, 254, 1) 50%, rgba(10, 239, 233, 1) 100%);
    
        /* opera 11.10+ */
        background:-o-linear-gradient(54deg, rgba(219, 142, 255, 1) 0%, rgba(219, 142, 255, 1) 0%, rgba(50, 151, 254, 1) 50%, rgba(10, 239, 233, 1) 100%);
    
        /* ie 6-9 */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0AEFE9', endColorstr='#DB8EFF', GradientType=0 );
    
        /* ie 10+ */
        background:-ms-linear-gradient(54deg, rgba(219, 142, 255, 1) 0%, rgba(219, 142, 255, 1) 0%, rgba(50, 151, 254, 1) 50%, rgba(10, 239, 233, 1) 100%);
    
        /* global 94%+ browsers support */
        background:linear-gradient(54deg, rgba(219, 142, 255, 1) 0%, rgba(219, 142, 255, 1) 0%, rgba(50, 151, 254, 1) 50%, rgba(10, 239, 233, 1) 100%);
    
    
    /* global 90%+ browsers support */
    /* background:conic-gradient(from 46deg at 21% 35%, rgba(219, 142, 255, 1) 0%, rgba(219, 142, 255, 1) 0%, rgba(50, 151, 254, 1) 50%, rgba(10, 239, 233, 1) 100%); */

}

.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color:transparent;
}

.section__padding{
    padding: 4rem 6rem;
}
.section__margin{
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  

  @media screen and(max-width:700px){
    .section__padding{
        padding: 4rem;
    }
    .section__margin{
        margin: 4rem;
    }
}
@media screen and(max-width:550px){
    .section__padding{
        padding: 4rem 2rem;
    }
    .section__margin{
        margin: 4rem 2rem;
    }
    .App{
        width: 100%;
    }
}