.Horizon__navbar{
    display: flex ;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.Horizon__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Horizon__navbar-links_logo{
    margin-right: 2rem;

}
.Horizon__navbar-links_logo img{
 width: 62px;
 height: 86px;
}
.Horizon__navbar-links_container{
    display: flex;
    flex-direction:row;
}
.Horizon__navbar-sign{
     display: flex;
     justify-content:  center;
     align-items: center;

}
.Horizon__navbar-links_container p,
.Horizon__navbar-sign p,
.Horizon__navbar-menu_container p{
    color:azure;    
 font-family: var(--font-family);
 font-weight: 500;
 font-size: 18px;
 line-height: 25px;
 text-transform: capitalize;

 margin: 0 1rem;
 cursor: pointer;
}
.Horizon__navbar-sign button,
.Horizon__navbar-menu_container button{
padding: 0.5rem 1rem;
color: #fff;
font-family: var(--font-family);
font-weight: 500;
left: 1153px;
top: 55px;
font-size: 18px;
 line-height: 25px;     
background: #FF4820;
border-radius: 5px;
border: 0;
outline:none;
cursor: pointer;
}

.Horizon__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
  
}
.Horizon__navbar-menu svg{
    cursor: pointer;
}

.Horizon__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    /* position:fixed; */
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width:  210px;
    border-radius:5px;
    box-shadow:  0 0 5 rgba( 0, 0, 0, 0.2);
    
}

.Horizon__navbar-menu_container p{
    margin: 1rem 0;
}
.Horizon__navbar-menu_container-links-sign{
    display:none;
}

@media screen and (max-width:1050px){
    .Horizon__navbar-links_container{
        display: none;
    }
    .Horizon__navbar-menu{
        display: flex;
    }
    
}
@media screen and (max-width:700px) {
    .Horizon__navbar{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width:550px) {
    .Horizon__navbar{
        padding: 2rem 4rem;
    }
    .Horizon__navbar-sign{
        display: none;
    }
    .Horizon__navbar-menu_container{
        top: 20px;

    }
    .Horizon__navbar-menu_container-links-sign{
        display: block ;
    }
}

